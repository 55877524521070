.paginations {
  display: flex;
  justify-content: center;
  list-style: none;
  padding: 0;
}

/* Page link styling */
.pages-items-containers {
  background-color: #dde6ed;
  margin: 0 5px;
  border: 0px solid #ccc;
  border-radius: 5px;
}

.pages-items-containers a {
  display: inline-block;
  padding: 5px 10px; /* Atur sesuai dengan kebutuhan */
  text-decoration: none;
  color: #474e68;
}

.pages-links-labels {
  font-weight: bold;
  font-size: 18px;
}

.pages-items-containers:hover {
  background-color: #bad0ff;
}

.pages-items-containers a:hover {
  color: #000000;
}

.actives {
  background-color: #407bff;
  border: 0px solid #ccc;
  border-radius: 5px;
  text-decoration: none;
}

.actives a {
  color: #ffffff; /* Warna teks untuk halaman aktif */
}

.activess a {
  color: #ffffff; /* Warna teks untuk halaman aktif saat dihover */
}

.custom-input::placeholder {
  font-size: 14px; /* Ubah ukuran font sesuai kebutuhan */
  color: #dddddd;
}

.custom-input::value {
  font-size: 14px; /* Ubah ukuran font sesuai kebutuhan */
  /* color: #dddddd; */
}
